<template>
  <div class="planWrap">
    <h2 class="mb30">教学计划</h2>
    <div class="noDataWrap" v-if="planList.length == 0">
      <img src="@/assets/images/noData.png" alt="" />
      <p>暂无学习的教学计划，快去学习课程吧~</p>
      <el-button type="primary" round @click="$router.push('/search')"
        >去学习</el-button
      >
    </div>
    <div v-if="planList.length > 0">
      <div
        v-for="(item, index) in planList"
        :key="index"
        class="itemWrap"
        @click="jumpDetail(item)"
      >
        <img src="../assets/images/planImg.png" alt="" />
        <div class="rightItem">
          <p class="rightTop">{{ item.name }}</p>
          <p>
            <span>{{ item.term_name }}</span>
            <i></i>
            <span>{{ item.faculty_name }}授课</span>
            <!--  <i></i>
            <span>共{{ item.course_num }}门课程</span>-->
          </p>
        </div>
      </div>
      <p class="moreBtn" @click="getMore">
        <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { planList } from "../assets/api";
export default {
  data() {
    return {
      planList: [],
      page: 1,
      limit: 20,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        school_id: 1,
        limit: this.limit,
        page: this.page,
      };
      planList(params).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          if (res.data.list.length > 0) {
            this.planList = this.planList.concat(res.data.list);
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList();
      }
    },
    jumpDetail(item) {
      this.$router.push({
        path: "/plan_detail",
        query: {
          project_id: item.project_id,
          metaType:2,
          fromType:3
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.planWrap {
  padding: 30px;
  box-sizing: border-box;
  .itemWrap {
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid #e9eaeb;
    cursor: pointer;
    img {
      width: 64px;
      height: 64px;
      margin-right: 20px;
    }
    .rightItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 0;
      box-sizing: border-box;
      font-size: 14px;
      color: #666;
      p {
        display: flex;
        align-items: center;
      }
      .rightTop {
        font-size: 16px;
        color: #333;
      }
      i {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #c9c9c9;
        margin: 0 15px;
      }
    }
  }
  .pageWrap {
    margin-top: 20px;
    float: right;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .noDataWrap {
    text-align: center;
    margin: 100px 113px;
    p {
      margin: 25px 0;
      font-size: 16px;
      color: #999999;
    }
    .el-button.is-round {
      padding: 10px 30px;
      &:hover{
        background:#4B78F0 !important;
        border: none !important;
        color: #FFF;
      }
    }
  }
}
.moreBtn {
  margin: 0 auto;
  margin-top: 30px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #666;
  &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
  i {
    font-size: 14px;
  }
}
</style>
